//mobile
footer {
  background-color: rgba(255,255,255,0);
  padding: 45px 0px;
  z-index: 2;
  position: relative;
  pointer-events: none;
  transition: .4s;
  &.hide {
    opacity: 0;
    transition: .4s;
  }
  li {
    list-style: none;
    img {
      width: 100%;
    }
  }
  small {
    font-weight: 900;
margin-bottom: -5px;
  }
}
.home footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  padding: 0 0 25px 0;
}
@media (max-width: 991px) {
  .wrap-time-line {
    display: none !important;
  }
}
//desktop
@include media-breakpoint-up(lg) {
}
