//mobile
.box-blue {
	background-color: $blue;
	border-radius: 15px;
	padding: 8px 20px 20px 20px;
}
.box-filme {
	transition: .4s;
	.wrapper-marker {
		transition: .4s;
	}
	&.hide {
		opacity: 0 !important;
		transition: .4s;
		pointer-events: none;
	}
	&.animate {
		&.marker {
			width: 0 !important;
			height: 0 !important;
			transition: .4s;
		}
		.wrapper-marker {
			transform: scale(0) translate3d(0, 0, 0);
			// filter: invert(1);
			transition: .4s;
		}

	}
}
.box-imgs {
	margin-left: -.5rem;
	margin-right: -.5rem;
}
.box-img-video {
	iframe, video {
		width: 100%;
	}
}
.box-img-grid-item img {
	width: 100% !important;
	padding: 5px;
}
/* clearfix */
.box-imgs-grid {
	width: 100%;
}
.box-imgs-grid:after {
	content: '';
	display: block;
	clear: both;
}
.grid-sizer,
.box-img-grid-item { width: 33.33%; }
.box-post-filme {
	img {
		height: 155px;
		object-fit: cover;
	}
}
//desktop
@include media-breakpoint-up(lg) {
}
