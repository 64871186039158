//mobile
input.search {
	background-color: $yellow;
	border: 2px solid $blue;
	border-radius: 13px;
	outline: none;
	box-shadow: none;
	border-radius: 14px;
	outline: 0;
	box-shadow: none;
	padding: 3px 35px 3px 15px;
	width: 100%;
	color: $blue;
font-weight: 900;
	&::placeholder {
		color: $blue;
		text-transform: uppercase;
		text-align: right;
	}
}
.ico-search {
	background: url('../images/icons/ico-search.svg') no-repeat center / contain;
	height: 22px;
	width: 22px;
	display: inline-block;
	position: absolute;
	right: 10px;
	top: calc(50% - 11px);
	border: none;
	outline: none;
	box-shadow: none;
}
//desktop
@include media-breakpoint-up(lg) {
}
