//mobile
.social-media {
	li {
		list-style: none;
		display: inline-block;
		margin-right: 2px;
		&:last-child {
			margin-right: 0;
		}
		img {
			width: 35px;
			height: 35px;
		}
	}
}

.ico-pin {
	background: url('../images/icons/pin.svg') no-repeat center / contain;
	width: 24px;
	height: 24px;
	display: inline-block;
	cursor: pointer;
	margin: 2px 0;
	&.active {
		background: url('../images/icons/pin2.svg') no-repeat center / contain;
	}
}
.ico-img {
	background: url('../images/icons/img.svg') no-repeat center / contain;
	width: 20px;
	height: 20px;
	display: inline-block;
	cursor: pointer;
	margin: 2px 0;
	&.active {
		background: url('../images/icons/img2.svg') no-repeat center / contain;
	}
}
.ico-filter {
	background: url('../images/icons/filter.svg') no-repeat center / contain;
	width: 24px;
	height: 24px;
	display: inline-block;
	cursor: pointer;
	margin: 2px 0;
	&.active {
		background: url('../images/icons/filter2.svg') no-repeat center / contain;
	}
}

.ico-youtube {
	background: url('../images/icons/ico-yt.svg') no-repeat center / contain;
	width: 67px;
	height: 47px;
	display: inline-block;
	vertical-align: middle;
}
//desktop
@include media-breakpoint-up(lg) {
}
