//mobile
.hamburger {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
}

.box-menu {
  background-color: $blue;
  position: absolute;
  top: 15px;
  opacity: 0;
  transition: .4s;
  pointer-events: none;
  z-index: 50;
  left: 15px;
  width: calc(100% - 30px);
  &.open {
    opacity: 1;
    transition: .4s;
    pointer-events: auto;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
    li {
      font-size: .9rem;
      color: $yellow;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 2px solid $yellow;
      padding: 8px 10px;
      &:nth-child(3) {
        font-style: italic;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.wrapper-logo {
  width: calc(100% - 50px);
}
//desktop
@include media-breakpoint-up(lg) {
  .box-menu {
    left: 0;
    width: 250px;
}
}
