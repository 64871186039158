// mobile

html, body {
  font-family: 'Arial', sans-serif !important;
  color: $blue;
  font-size: 16px;
  font-weight: 400;
  background: $yellow;
}

body {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #000061;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #000061;
  }
  // &::-webkit-scrollbar-track{
  //   background: #ffffff;
  //   border-radius: 8px;
  //   box-shadow: inset -30px -30px 0px #f0f0f0;
  // }
  &.search main {
    overflow-x: hidden;
  }
}
html {
  &.hidden-overflow {
    overflow-y: hidden;
  }
}
body:not(body.home) {
  padding-top: 64px;
}

main {
  // min-height: 100vh;
  min-height: calc(100vh - 64px);
  background: $yellow;
}

body.home main {
  min-height:100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-primary;
}

h1 {
  font-size: 1.5em; //24px
  line-height: normal;
  font-weight: 900;
}
h2 {
  font-size: 1.25em; //20px
text-transform: uppercase;
font-weight: 900;
}
h3 {
}
h4 {
}
h5 {
}

p {
  font-size: 1em; //18px
}

span {
}
small {
  font-size: 0.625em;//10px
  line-height: normal;
}

label {
  font-size: 1em; //20px
  text-transform: uppercase;
}

b, strong {
  font-weight: 700;
}

strong p {
  font-weight: 700 !important;
}
a, a:hover, a:active, a:focus {
  text-decoration: none;
  color: inherit;
}
.f-18 {
  font-size: 1em;
}

//desktop
@include media-breakpoint-up(lg) {

  h1 {
    font-size: 1.75em; //30px
  }
  h2 {
    font-size: 1.25em; //22px
  }
  .f-18 {
    font-size: 1.125em;
  }


}
