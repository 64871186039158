//mobile
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.w-90 {
  width: 90px;
}
.vh-100 {
  height: calc(100vh - 64px) !important;
  max-height: 430px;
}

.table {
  color: $blue;
}
.table thead th {
    border-bottom: 1px solid $blue;
  }
  .table td {
    border-top: 1px solid $blue;
  }
  .table th {
    border: none;
  }
  tbody tr:first-child td {
    border-top: none;
}

  tr td:last-child {
    word-break: break-word;
}

tr td:first-child {
    display: block;
    width: 240px;
}
//desktop
@include media-breakpoint-up(lg) {
  .vh-100 {
    max-height: none;
  }
}
