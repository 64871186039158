//mobile
.btn {
  font-weight: 400;
  font-family: $font-primary;
  text-transform: lowercase;
  padding: 5px;
  max-width: 240px;
  width: 100%;
}
.btn-primary, .btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):focus {
  color: white;
  background-color: $blue;
  border-color: $blue;
  outline: none;
  box-shadow: none;
}
.btn-secondary, .btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):focus {
  background-color: black;
  border-color: black;
  color: white;
  outline: none;
  box-shadow: none;
}
.btn-outline-primary, .btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):focus {
  border-color: white;
  color: white;
  background-color: rgba(255,255,255,0);
  outline: none;
  box-shadow: none;
  border-width: 2px;
  &.black {
    color: black;
  }
}
.btn-outline-secondary, .btn-outline-secondary:hover, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled):focus {
  border-color: black;
  color: black;
  background-color: rgba(255,255,255,0);
  outline: none;
  box-shadow: none;
  border-width: 2px;
}

.btn-lang {
  cursor: pointer;
}
.lang-mobile .box-lang li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.load-more {
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}
.btn-assistir {
  display: inline-block;
  background: $blue;
  border-radius: 5px;
  padding: 0 5px;
  color: $yellow;
  font-weight: 900;
  &:hover, &:active, &:hover:active, &:visited {
    color: $yellow;
  }
}
//desktop
@include media-breakpoint-up(lg) {
  .btn {
    padding: 10px;
    max-width: 280px;
  }

}
