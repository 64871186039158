//mobile
.controlHolder {
  display: none !important;
}
.fullscreenToggle {
  display: none !important;
}
.zoomIn {
  background: url('../css/assets/zoom-mais.svg') no-repeat !important;
}
.zoomOut {
  background: url('../css/assets/zoom-menos.svg') no-repeat !important;
}
.marker {
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 97,.75);
  border-radius: 100%;
  transition: .4s;
  &:hover {
    background-color: $blue;
    transition: .4s;
    z-index: 10;
    .marker-name {
      background-color: $blue;
      transition: .4s;
    }
    .marker-content {
      opacity: 1;
      pointer-events: auto;
      transition: .4s;
    }
  }
  .marker-name, .marker-content {
    background-color: rgba(0, 0, 97,.75);
    transition: .4s;
    color: $yellow;
    // font-size: 1rem;
    text-transform: uppercase;
    width: 100%;
    border-radius: 7px;
    padding: 5px;
    line-height: normal;
    margin: 0;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.2px;
    font-size: 11px;
  }
  .marker-content {
    background-color: $blue;
    text-transform: initial;
    opacity: 0;
    pointer-events: none;
    margin-top: 3px;
    transition: .4s;
  }
}
.wrapper-marker {
  width: max-content;
  -webkit-width: max-content;
  -moz-width: max-content;
  max-width: 120px;
  min-width: 100px;
  margin-top: 15px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: -52.5px;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 30px !important;
  width: 100% !important;
  border-radius: 7px !important;
  border: none !important;
  background: $blue !important;
  .tooltipster-content {
    color: $yellow !important;
  }
}
.tooltipster-sidetip.tooltipster-light .tooltipster-arrow {
  display: none !important;
}
.time-line {
  b {
    font-size: 10px;
    font-weight: 900;
    margin-top: auto;
    margin-bottom: -7px;
    position: relative;
    // transition: .4s;
    // &.hide {
    //   opacity: 0;
    //   transition: .4s;
    // }
    &.first, &.last {
      font-size: 13px;
      margin-bottom: 0;
      &:after {
        height: 27px;
        bottom: -27px;
      }
    }
    &:after {
      content: ' ';
      height: 10px;
      width: 2px;
      background-color: $blue;
      display: inline-block;
      position: absolute;
      bottom: -11px;
      left: calc(50% - 1px);
    }
    &:nth-child(2) {
      left: -2px;
    }
    &:nth-child(3) {
      left: 1px;
    }
    &:nth-child(4) {
      left: 3px;
    }
    &:nth-child(5) {
      left: 5px;
    }
    &:nth-child(6) {
      left: 7px;
    }
    &:nth-child(7) {
      left: 9px;
    }
    &:nth-child(8) {
      left: 11px;
    }
  }
}
.controllers {
  background: #e0d551;
  width: 43px;
  height: 105px;
  position: fixed;
  bottom: 30px;
  right: 25px;
  z-index: 489562;
  border-radius: 0 0 50px 50px!important;
  padding: 5px!important;
  text-align: center;
}
.zoomHolder {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #000061;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #000061;
  }
}
//desktop
@include media-breakpoint-up(lg) {
  .controlHolder {
    position: fixed !important;
    display: block !important;
    border-radius: 0!important;
    background: $yellow!important;
    right: 25px !important;
    top: auto!important;
    bottom: 130px;
    border-radius: 50px 50px 0 0!important;
    padding: 5px 5px 0px 8px!important;
  }

  .wrapper-marker {
    max-width: 145px;
    margin-left: -67.5px;
  }

  .marker {
    .marker-name, .marker-content {
      line-height: 16px;
      font-size: 13px;
    }
  }

}
