//mobile
header {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  background-color: $yellow;
  max-height: 64px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 3;
  &.open-menu {
    .logo svg path:first-child {
      fill: white;
    }
  }
}
.home {
  header {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    height: 64px;
  }
}
.logo {
  margin-top: -6px;
  li {
    list-style: none;
    img {
      width: 100%;
    }
  }
}
.float-logos {
  position: fixed;
  left: 0;
  right: 0;
  top: 90px;
  z-index: 1;
  pointer-events: none;
  transition: .4s;
  &.hide {
    opacity: 0;
    transition: .4s;
  }
  li {
    list-style: none;
    img {
      width: 100%;
    }
  }
}

.social-media {
  max-width: 120px;
  width: 100%;
}
//desktop
@include media-breakpoint-up(lg) {
}
