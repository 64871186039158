//mobile
#nossas-solucoes {
  p {
    line-height: 36px;
  }
}
#noticias {
  .list li {
    font-size: 1em; //20px
    font-weight: 400;
    margin-bottom: 32px;
    z-index: 1;
    position: relative;
  }
  .li-item {
    position: absolute;
    top: 40px;
    right: 20%;
    img {
      height: 400px;
      object-fit: cover;
      width: 600px;
      z-index: -1;
    }
  }
}
#diga-oi {
  #anchor-color {
    height: auto;
    margin-top: 25%;
  }
}
#middle-servicos {
  #anchor-color {
    height: calc(65% - 250px);
    margin-top: 250px;
  }
}
//desktop
@include media-breakpoint-up(lg) {
  #nossas-solucoes {
    img {
      margin-left: -50px;
      margin-top: 50px;
    }
  }
  #noticias {
    .list li {
      font-size: 1.6em; //32px
    }
  }
}
