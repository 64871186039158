//mobile
.modal-backdrop {
	opacity: 0 !important;
}
.modal-dialog {
	max-width: 375px;
}
.modal-content {
	border: 0;
	background-color: $blue;
}
.modal-body {
	background-color: $blue;
	color: $yellow;
	border: 0;
	border-radius: 5px;
	padding: 10px 10px 15px 10px;
	text-align: justify;
	p {
		margin-bottom: 0;
	}
	.btn-modal {
		background-color: $yellow;
		display: inline-block;
		padding: 0px 30px;
		border-radius: 5px;
		font-weight: 700;
		color: $blue;
		float: right;
		margin-top: -15px;
		cursor: pointer;
	}
}
//desktop
@include media-breakpoint-up(lg) {
}
