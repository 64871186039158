//mobile
.semibold {
  font-weight: 600;
}
.white {
  color: white;
}
.black {
  color: black;
}
.blue {
  color: $blue;
}

.uppercase {
  text-transform: uppercase;
}
//desktop
@include media-breakpoint-up(lg) {
}
