// mobile
// .container {
//   max-width: calc(100% - 30px);
// }

//desktop
@include media-breakpoint-up(lg) {
  .container {
    max-width: calc(100% - 120px);
  }
}
