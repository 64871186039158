.wrap-all {
  max-width: 400px;
  flex: 0 0 400px;
}
.wrap-wrapper {
  position: sticky;
  top: calc(50vh - 120px);
  height: 300px;
  margin-top: 120px;
}
.wrap-all-inner {
  width: 100%;
  position: relative;
  height: 100%;
}
.circle {
  border: 2px solid black;
  border-radius: 100%;
  position: absolute;
  width: 100%;
  .wrap-text {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    p {
      color: black;
      font-weight: bold;
      margin-bottom: 0;
      opacity: 1;
    }
  }

  &.item-1 {
    width: 260px;
    height: 260px;
    margin-left: 100px;
    margin-top: 0;
    transition: 1s;
    &.show {
      margin-left: 0;
      width: 260px;
      height: 260px;
      transition: 1s;
      p {
        opacity: 1;
        transition: 1s;
      }
    }
    &.stopped {
      margin-left: 0;
      width: 190px;
      height: 190px;
      transition: 1s;
      p {
        opacity: 0;
        transition: 1s;
      }
    }
  }
  &.item-2 {
    width: 260px;
    height: 260px;
    margin-left: 165px;
    margin-top: -50px;
    opacity: 0;
    transition: 1s;
    &.show {
      margin-left: 165px;
      margin-top: -50px;
      opacity: 1;
      width: 260px;
      height: 260px;
      transition: 1s;
      p {
        opacity: 1;
        transition: 1s;
      }
    }
    &.stopped {
      margin-left: 150px;
      opacity: 1;
      width: 190px;
      height: 190px;
      transition: 1s;
      margin-top: 0;
      p {
        opacity: 0;
        transition: 1s;
      }
    }
  }
  &.item-3 {
    width: 260px;
    height: 260px;
    margin-left: 40px;
    margin-top: 80px;
    opacity: 0;
    transition: 1s;
    &.show {
      margin-left: 40px;
      margin-top: 80px;
      opacity: 1;
      width: 260px;
      height: 260px;
      transition: 1s;
      p {
        opacity: 1;
        transition: 1s;
      }
    }
    &.stopped {
      margin-left: 0;
      margin-top: 80px;
      opacity: 1;
      width: 190px;
      height: 190px;
      transition: 1s;
      p {
        opacity: 0;
        transition: 1s;
      }
    }
  }
  &.item-4 {
    width: 260px;
    height: 260px;
    margin-left: 135px;
    margin-top: 80px;
    opacity: 0;
    transition: 1s;
    &.show{
      margin-left: 135px;
      margin-top: 80px;
      opacity: 1;
      width: 260px;
      height: 260px;
      transition: 1s;
      p {
        opacity: 1;
        transition: 1s;
      }

    }
    &.stopped{
      margin-left: 150px;
      margin-top: 80px;
      opacity: 1;
      width: 190px;
      height: 190px;
      transition: 1s;
      p {
        opacity: 0;
        transition: 1s;
      }
    }
  }
}

.losango {
  background-color: black;
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: 160px;
  margin-top: 125px;
  transform: rotate(
  36deg
  ) skewY(
  25deg
  );
  border-radius: 2px;
  opacity: 0;
  transition: 1s;
  &.show {
    opacity: 1;
    transition: 1s;
  }
}

.losango-p {
  background: url('../images/losango.svg') no-repeat center / auto;
  width: 148px;
  height: 208px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  top: 32px;
  opacity: 0;
  transition: .4s;
  &.show {
    opacity: 1;
    transition: 1s;
  }
  p {
    margin: 0;
  }
}
.wrapper-circles {
  margin: 0 auto 25px auto;
  position: relative;
  .circle-mobile {
    position: absolute;
    width: 190px;
    height: 190px;
    border: 2px solid black;
    border-radius: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      margin: 0;
    }
    &.small {
      width: 140px;
      height: 140px;
    }
  }
  &.circle-1 {
    width: 300px;
    height: 190px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }
  &.circle-2 {
    width: 300px;
    height: 190px;
    .circle-mobile {
      top: 0;
      right: 0;
      &.small {
        top: calc(50% - 70px);
        left: 0;
      }
    }
  }
  &.circle-3 {
    width: 240px;
    height: 265px;
    .circle-mobile {
      top: 70px;
      left: calc(50% - 95px);
      &.small {
        top: 0;
        left: 0;
        &:nth-child(2) {
          top: 0;
          right: 0;
          left: auto;
        }
      }
    }
  }
  &.circle-4 {
    width: 288px;
    height: 235px;
    .circle-mobile {
      top: calc(50% - 70px);
      left: calc(50% - 50px);
      padding-top: 50px;
      padding-left: 5px;
      &.small {
        top: 0;
        left: 0;
        &:nth-child(2) {
          top: 0;
          left: calc(140px - 35px);
        }
        &:nth-child(3) {
          top: calc(50% - 70px);
          left: 0;
        }
      }
    }
  }
  &.circle-5 {
    width: 290px;
    height: 245px;
    .circle-mobile {
      width: 169px;
      height: 169px;
      &.small {
        top: 0;
        left: 0;
        &:nth-child(2) {
          top: 0;
          left: auto;
          right: 0;
        }
        &:nth-child(3) {
          top: auto;
          left: 0;
          bottom: 0;
        }
        &:nth-child(4) {
          top: auto;
          left: auto;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}


.losango-mobile {
  background: url('../images/losango.svg') no-repeat center / auto;
  width: 110px;
  height: 154px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
  }
}

.losango-mobile-full {
  background-color: black;
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  transform: rotate(37deg) skewY(15deg);
  border-radius: 2px;
  top: calc(50% - 13px);
  left: calc(50% - 13px);
  border-radius: 2px;
}
@include media-breakpoint-up(lg) {
}
