//mobile

.btn {
  font-weight: bold;
  text-transform: uppercase;
}
.btn:hover {
  background-color: #ccc;
  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
  color: #222;
}
.btn:active,
.btn.is-checked {
  background-color: #2175b0;
  color: #fff;
}

/* --------------------------------------- */
/* Isotope Grid */
/* --------------------------------------- */

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}


/* --------------------------------------- */
/* Bootstrap Slider */
/* --------------------------------------- */
.sliders { padding: 15px 0 30px 0; }
.filter-section .filter-label {
  display: block;
  font-weight: bold;
}
.bootstrap-slider .slider-selection { background: $blue; }


.slider.slider-horizontal {
  width: calc(100% - 35px);
  height: 20px;
  margin: 9px 0px 0 0px;
}
b.filter-min {
  position: absolute;
  top: 0;
  left: 0;
}
b.filter-max {
  position: absolute;
  top: 0;
  right: 0;
}
.bootstrap-slider {
  position: relative;
}
.tooltip-min, .tooltip-max{
  opacity: 1 !important;
}
.slider.slider-horizontal .slider-track {
  height: 2px;
  margin-top: -2px;
  background-color: $blue;
  box-shadow: none;
  background-image: none;
}

.slider-selection {
  background: $blue;
}
.slider-handle {
  background: $blue;
}
.tooltip-inner {
  color: $blue;
  background-color: $yellow;
  // background-color: rgba(255,255,255,0);
  border-radius: 0;
  font-weight: 900;
  font-size: 14px!important;
  font-family: 'Arial';
  bottom: 0;
  padding: 4px 0 0 0!important;
}
.tooltip .arrow {
  display: none;
}

.wrap-time-line {
  bottom: 130px;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  transition: .4s;
  .time-line {
    background-color: $yellow;
    border-radius: 13px;
    padding: 10px 15px 5px 15px;
    // .time-inner {
    //   width: calc(100% + 10px);
    // }
  }
  &.hide {
    opacity: 0;
    transition: .4s;
    pointer-events: none;
  }
}
.item-inner {
  strong p {
    font-weight: 900 !important;
  }
  p {
    margin-bottom: 0 !important;
  }
}
.text-filter {
  font-weight: 900;
}
.wrap-group-filters {
  max-height: 450px;
  overflow: auto;
  max-width: 280px;
  position: absolute;
  left: 0;
  top: 40px;
  background-color: $blue;
  opacity: 0;
  transition: .4s;
  pointer-events: none;
  z-index: 50;
  &.open {
    opacity: 1;
    transition: .4s;
    pointer-events: auto;
  }
  span {
    font-size: 14px;
    color: $yellow;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 2px solid $yellow;
    padding: 8px 10px;
    line-height: normal;
    cursor: pointer;
    &:hover {
      color: $yellow;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #000061;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb:hover{
    background: #000061;
  }
  &::-webkit-scrollbar-track{
    background: #ffffff;
    border-radius: 0px;
    box-shadow: inset -30px -30px 0px #E8E8E8;
  }
}
.btn-cidade, .btn-trama, .limpar {
  cursor: pointer;
}

.wrapper-trama-de-palavras {
  position: absolute;
  left: 0;
  z-index: 10;
  background-color: $yellow;
  width: 100%;
  top: 350px;
  right: 0;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  align-items: start;
  opacity: 0;
  pointer-events: none;
  transition: .4s;
  &.open {
    opacity: 1;
    pointer-events: auto;
    transition: .4s;
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // line-height: 2.8rem;
    max-width: 100%;
    li {
      font-weight: 700;
      text-transform: uppercase;
      margin: 0 2px;
      line-height: 2.2rem;
    }
    li:nth-child(1) {
      font-size: 25px;
    }
    li:nth-child(2) {
      font-size: 65px;
      margin-left: 0;
    }
    li:nth-child(3) {
      font-size: 16px;
    }
    li:nth-child(4) {
      font-size: 18px;
      // margin-left: -5%;
    }
    li:nth-child(5) {
      font-size: 25px;
    }
    li:nth-child(6) {
      font-size: 15px;
    }
    li:nth-child(7) {
      font-size: 25px;
    }
    li:nth-child(8) {
      font-size: 52px;
      // margin-left: -5%;
    }
    li:nth-child(9) {
      font-size: 28px;
    }
    li:nth-child(10) {
      font-size: 22px;
    }
    li:nth-child(11) {
      font-size: 38px;
      margin-left: 0;
    }
    li:nth-child(12) {
      font-size: 18px;
    }
    li:nth-child(13) {
      font-size: 40px;
    }
    li:nth-child(14) {
      font-size: 28px;
      // margin-left: -9%;
    }
    li:nth-child(15) {
      font-size: 22px;
    }
    li:nth-child(16) {
      font-size: 40px;
    }
    li:nth-child(17) {
      font-size: 25px;
    }
    li:nth-child(18) {
      font-size: 28px;
    }
    li:nth-child(19) {
      font-size: 40px;
    }
    li:nth-child(20) {
      font-size: 45px;
    }
    li:nth-child(21) {
      font-size: 30px;
    }
  }
}
.search .sliders.time-line .slider.slider-horizontal {
  width: calc(100% - 35px);
  margin: 9px 0 0px 17px;
}
.search .sliders {
  padding: 30px 0 40px;
}
.item {
  &.hidden{
    visibility:hidden;
    width:0px!important;
    height:0px!important;
    margin:0px!important;
    padding:0px!important;
    display: none;
  }
}
.time-line {
    z-index: 1;
}
//desktop
@include media-breakpoint-up(lg) {
  .wrapper-trama-de-palavras {
    top: 325px;
    padding: 50px;
    ul {
      max-width: 1100px;
      li {
        margin: 0 10px;
        line-height: 2.8rem;
      }
      li:nth-child(1) {
        font-size: 45px;
      }
      li:nth-child(2) {
        font-size: 85px;
        margin-left: 25%;
      }
      li:nth-child(3) {
        font-size: 30px;
      }
      li:nth-child(4) {
        font-size: 25px;
        // margin-left: -5%;
      }
      li:nth-child(5) {
        font-size: 35px;
      }
      li:nth-child(6) {
        font-size: 45px;
      }
      li:nth-child(7) {
        font-size: 30px;
      }
      li:nth-child(8) {
        font-size: 35px;
        // margin-left: -5%;
      }
      li:nth-child(9) {
        font-size: 45px;
      }
      li:nth-child(10) {
        font-size: 70px;
      }
      li:nth-child(11) {
        font-size: 60px;
        margin-left: 10%;
      }
      li:nth-child(12) {
        font-size: 30px;
      }
      li:nth-child(13) {
        font-size: 40px;
      }
      li:nth-child(14) {
        font-size: 35px;
        // margin-left: -9%;
      }
      li:nth-child(15) {
        font-size: 55px;
      }
      li:nth-child(16) {
        font-size: 40px;
      }
      li:nth-child(17) {
        font-size: 25px;
      }
      li:nth-child(18) {
        font-size: 40px;
      }
      li:nth-child(19) {
        font-size: 40px;
      }
      li:nth-child(20) {
        font-size: 60px;
      }
      li:nth-child(21) {
        font-size: 30px;
      }
    }
  }
}
